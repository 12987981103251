import * as _plugin2 from "tailwindcss/plugin";
var _plugin = _plugin2;
try {
  if ("default" in _plugin2) _plugin = _plugin2.default;
} catch (e) {}
import * as _lodash2 from "lodash.merge";
var _lodash = _lodash2;
try {
  if ("default" in _lodash2) _lodash = _lodash2.default;
} catch (e) {}
import * as _lodash4 from "lodash.castarray";
var _lodash3 = _lodash4;
try {
  if ("default" in _lodash4) _lodash3 = _lodash4.default;
} catch (e) {}
import _styles from "./styles";
var exports = {};
const plugin = _plugin;
const merge = _lodash;
const castArray = _lodash3;
const styles = _styles;
const computed = {
  // Reserved for future "magic properties", for example:
  // bulletColor: (color) => ({ 'ul > li::before': { backgroundColor: color } }),
};
function inWhere(selector, {
  className,
  prefix
}) {
  let prefixedNot = prefix(`.not-${className}`).slice(1);
  let selectorPrefix = selector.startsWith(">") ? `.${className} ` : "";
  if (selector.endsWith("::before")) {
    return `:where(${selectorPrefix}${selector.slice(0, -8)}):not(:where([class~="${prefixedNot}"] *))::before`;
  }
  if (selector.endsWith("::after")) {
    return `:where(${selectorPrefix}${selector.slice(0, -7)}):not(:where([class~="${prefixedNot}"] *))::after`;
  }
  if (selector.endsWith("::marker")) {
    return `:where(${selectorPrefix}${selector.slice(0, -8)}):not(:where([class~="${prefixedNot}"] *))::marker`;
  }
  return `:where(${selectorPrefix}${selector}):not(:where([class~="${prefixedNot}"] *))`;
}
function isObject(value) {
  return typeof value === "object" && value !== null;
}
function configToCss(config = {}, {
  target,
  className,
  prefix
}) {
  function updateSelector(k, v) {
    if (target === "legacy") {
      return [k, v];
    }
    if (Array.isArray(v)) {
      return [k, v];
    }
    if (isObject(v)) {
      let nested = Object.values(v).some(isObject);
      if (nested) {
        return [inWhere(k, {
          className,
          prefix
        }), v, Object.fromEntries(Object.entries(v).map(([k, v]) => updateSelector(k, v)))];
      }
      return [inWhere(k, {
        className,
        prefix
      }), v];
    }
    return [k, v];
  }
  return Object.fromEntries(Object.entries(merge({}, ...Object.keys(config).filter(key => computed[key]).map(key => computed[key](config[key])), ...castArray(config.css || {}))).map(([k, v]) => updateSelector(k, v)));
}
exports = plugin.withOptions(({
  className = "format",
  target = "modern"
} = {}) => {
  return function ({
    addVariant,
    addComponents,
    theme,
    prefix
  }) {
    let modifiers = theme("typography");
    let options = {
      className,
      prefix
    };
    for (let [name, ...selectors] of [["headings", "h1", "h2", "h3", "h4", "h5", "h6", "th"], ["h1"], ["h2"], ["h3"], ["h4"], ["h5"], ["h6"], ["p"], ["a"], ["blockquote"], ["figure"], ["figcaption"], ["strong"], ["em"], ["code"], ["pre"], ["ol"], ["ul"], ["li"], ["table"], ["thead"], ["tr"], ["th"], ["td"], ["img"], ["video"], ["hr"], ["lead", "[class~=\"lead\"]"]]) {
      selectors = selectors.length === 0 ? [name] : selectors;
      let selector = target === "legacy" ? selectors.map(selector => `& ${selector}`) : selectors.join(", ");
      addVariant(`${className}-${name}`, target === "legacy" ? selector : `& :is(${inWhere(selector, options)})`);
    }
    addComponents(Object.keys(modifiers).map(modifier => ({
      [modifier === "DEFAULT" ? `.${className}` : `.${className}-${modifier}`]: configToCss(modifiers[modifier], {
        target,
        className,
        prefix
      })
    })));
  };
}, () => {
  return {
    theme: {
      typography: styles
    }
  };
});
export default exports;